<template>
  <div class="row">
    <div class="col-md-3">
      <b-calendar v-model="date" v-on:input="changeDate" :date-info-fn="dateClass" block start-weekday="1"></b-calendar>
    </div>
    <div class="col-md-9 mt-3 mt-md-0">
      <b-card no-body>
        <b-card-header>
          <div class="d-flex align-items-center">
            <div class="mr-auto">
              Evenementen &ndash; {{ datehelper(date).getWeekday() }} {{ formattedDate }}
              <b-badge variant="info" pill>{{ todayEvents.length }}</b-badge>
            </div>
            <b-button-group>
              <filterbutton filtertype="location" :input="events" @change="applyFilter" />
            </b-button-group>
          </div>
        </b-card-header>

        <b-card-body v-if="loading">
          <b-spinner variant="info"/>
        </b-card-body>

        <b-card-body v-if="!loading && todayEvents.length === 0" class="text-center font-italic small text-muted">
          Er zijn geen evenementen gevonden.
        </b-card-body>

        <b-list-group flush v-if="!loading && todayEvents.length">
          <b-list-group-item v-for="event in todayEvents" :key="event.id">
            <span class="float-right small text-muted pt-1">
              {{ datehelper(event.starttime).formatTime() }}
              &ndash;
              {{ datehelper(event.endtime).formatTime() }}
            </span>
            <router-link :to="{name: 'Event', params: { id: event.id }}">{{ event.name }}</router-link>
            <div class="text-muted small">
              <span>{{ event.status.name }}</span>
              &ndash;
              <span v-for="location in event.locations" :key="location.id">{{ location.name }}</span>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex"
import filterbutton from "@/components/Filter";
import {datehelper} from "@/helpers/datehelper";

export default {
  components: {filterbutton},
  data() {
    return {
      loading: true,
      date: new Date().toDateString(),
      events: [],
      filterEvents: [],
      todayEvents: [],
      dates: []
    }
  },
  computed: {
    formattedDate() {
      return datehelper(this.date).formatDate()
    },
    redirectDate() {
      return datehelper(this.date).formatRevDate()
    }
  },
  methods: {
    ...mapActions(['apiGet', 'apiGetPaginate']),
    datehelper,
    updateEvents() {
      this.loading = true
      this.events = []

      const td = datehelper(this.date).addDays(20).formatDate()
      this.apiGetPaginate("events/date:"+ this.formattedDate +" TO "+ td).then((result) => {
        this.events = result
        this.loading = false
      })
    },
    changeDate() {
      if(this.$route.params.date !== this.date) {
        this.$router.push({name: 'Events', params: {date: this.date}})
        this.updateEvents()
      }
    },

    applyFilter(data) {
      this.filterEvents = data
      this.todayEvents = []
      this.dates = []
      data.forEach((event) => {
        if(event.startdate === this.date)
          this.todayEvents.push(event)

        let dt = new Date(event.starttime)
        let m = (dt.getMonth() + 1 < 10) ? "0"+ (dt.getMonth() + 1) : dt.getMonth() + 1
        let d = (dt.getDate() < 10) ? "0"+ dt.getDate() : dt.getDate()
        this.dates.push(dt.getFullYear() +"-"+ m +"-"+ d)
      })
    },

    dateClass(ymd) {
      return (this.dates.includes(ymd)) ? "table-info" : ""
    },
  },
  mounted() {
    if(this.$route.params.date != null) {
      if(this.$route.params.date === 'today') {
        this.date = this.redirectDate
        this.$router.push({ name: 'Events', params: { date: this.redirectDate } })
      } else {
        this.date = this.$route.params.date
      }
    }
    this.updateEvents()
  }
}
</script>